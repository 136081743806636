import './bootstrap' // eslint-disable-line import/order
import '../css/app.css' // eslint-disable-line import/order

import { createInertiaApp, Link } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h } from 'vue'
import { ZiggyVue } from 'ziggy-js'

const appName = window.document.querySelectorAll('title')[0].textContent ?? 'Laravel'

void createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    // @ts-expect-error resolvePageComponent is not typed
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(ZiggyVue)
      .use(plugin)
      .component('inertia-link', Link)
      .component('InertiaLink', Link)

      .mount(el)
  },
  progress: {
    color: '#32c54c',
  },
})
